import React from 'react'

function Footer () {

  return (
    <div id="footer">
      <a href="https://github.com/jeannie2/mern-url-shortener-frontend">GitHub</a>
    </div>
  )
}

export default Footer
